import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import GatsbyImage from 'common/GatsbyImage';
import { Link } from 'gatsby';

import Popup from './Popup';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import './Results.scss';
import './RecommendedProduct.scss';

SwiperCore.use([Navigation]);

interface ResultsProps {
  result: any;
  handlePreviousButtonClick: () => void;
  handleRestartButtonClick: () => void;
  images: any;
  buttonText: { [key: string]: string };
}

const Results: React.FC<ResultsProps> = ({
  result,
  handlePreviousButtonClick,
  handleRestartButtonClick,
  images,
  buttonText,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="results">
      <div className="help-me-choose__content-wrapper">
        <div className="results__intro">{result.text}</div>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          allowTouchMove={result.products.length > 1}
        >
          {result.products.map((product) => (
            <SwiperSlide key={product.name}>
              <div className="recommended-product">
                <GatsbyImage
                  className="recommended-product__image"
                  image={images.find((p) => p.properties.id === product.imageId).properties.image}
                />

                <div className="recommended-product__name">{product.name}</div>
                <div className="recommended-product__description">{product.description}</div>
                <div>
                  <div className="recommended-product__fineprint">{result.finePrint}</div>
                  <div className="recommended-product__button-group">
                    <Link className="recommended-product__button" to={product.productUrl}>
                      {buttonText.learnMore}
                    </Link>
                    <div
                      className="recommended-product__button"
                      onClick={togglePopup}
                      role="button"
                      tabIndex={0}
                    >
                      {buttonText.moreTips}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="nav">
          <div className="nav__link-container">
            <div
              className="nav__button back"
              onClick={handlePreviousButtonClick}
              role="button"
              tabIndex={0}
            >
              <span className="icon-arrow" />
            </div>
            <div className="nav__link-label">{buttonText.back}</div>
          </div>
          <div className="nav__link-container">
            <div
              onClick={handleRestartButtonClick}
              className="nav__button"
              role="button"
              tabIndex={0}
            >
              <span className="icon-spinner" />
            </div>
            <div className="nav__link-label">{buttonText.restart}</div>
          </div>
        </div>
        {isPopupVisible && <Popup onClose={togglePopup} result={result} images={images} />}
      </div>
    </div>
  );
};

export default Results;
