import React, { FC } from 'react';
import classNames from 'classnames';
import './Navigation.scss';

interface NavigationProps {
  isCompleted: boolean;
  pageHistory: string[];
  handlePreviousButtonClick: () => void;
  handleNextButtonClick: () => void;
  handleRestartButtonClick: () => void;
  isNextButtonEnabled: boolean;
}

const Navigation: FC<NavigationProps> = ({
  isCompleted,
  pageHistory,
  handlePreviousButtonClick,
  handleNextButtonClick,
  handleRestartButtonClick,
  isNextButtonEnabled,
}) => (
  <div className="nav">
    {pageHistory.length > 1 && (
      <div
        className="nav__link-container"
        onClick={handlePreviousButtonClick}
        role="button"
        tabIndex={0}
      >
        <div className="nav__button back">
          <span className="icon-arrow" />
        </div>
        <div className="nav__link-label">BACK</div>
      </div>
    )}
    <div
      className="nav__link-container"
      role="button"
      tabIndex={0}
      onClick={isCompleted ? handleRestartButtonClick : handleNextButtonClick}
    >
      <div
        disabled={!isNextButtonEnabled}
        className={classNames('nav__button', {
          disabled: !isNextButtonEnabled,
        })}
      >
        <span
          className={classNames(`icon-${isCompleted ? 'spinner' : 'arrow'}`, {
            'icon-arrow--reverse': !isCompleted,
          })}
        />
      </div>
      <div className="nav__link-label">{isCompleted ? 'RESTART' : 'NEXT'}</div>
    </div>
  </div>
);

export default Navigation;
