import React from 'react';
import classNames from 'classnames';
import './ProgressIndicator.scss';

interface IPropsProgressIndicator {
  pages: any;
  currentPage: string;
  isCompleted: boolean;
}

const ProgressIndicator: React.FC<IPropsProgressIndicator> = ({
  pages,
  currentPage,
  isCompleted,
}) => {
  const totalSteps = pages.length;
  const currentStep = isCompleted ? totalSteps : pages.findIndex((p) => p.id === currentPage);

  return (
    <div className="progress">
      {pages.map((page, index) => (
        <React.Fragment key={page}>
          <div
            className={classNames('progress__line', {
              'progress__line--completed': isCompleted || index <= currentStep,
            })}
          />
          <div
            className={classNames('progress__dot', {
              'progress__dot--completed': isCompleted || index < currentStep,
              'progress__dot--current': index === currentStep,
            })}
          />
          {index === totalSteps - 1 && (
            <div
              className={classNames('progress__line', {
                'progress__line--completed': isCompleted || index < currentStep,
              })}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressIndicator;
