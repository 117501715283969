import React from 'react';
import GatsbyImage from 'common/GatsbyImage';

import './Popup.scss';

interface PopupProps {
  onClose: () => void;
  result: any;
  images: any;
}

const Popup: React.FC<PopupProps> = ({ onClose, result, images }) => (
  <div className="popup-container">
    <div className="popup">
      <div className="popup__header">
        <div className="popup__close" onClick={onClose} role="button" tabIndex={0}>
          <span className="icon-cross" />
        </div>
      </div>
      <div className="popup__content">
        <div className="popup__heading">{result.popupHeading}</div>
        <ul className="help-me-choose__bullets">
          {result.popupTips.map((bullet) => (
            <li key={bullet}>{bullet}</li>
          ))}
        </ul>
        <GatsbyImage
          className="popup__image"
          image={images.find((p) => p.properties.id === 'Steaming Cup').properties.image}
        />
      </div>
    </div>
  </div>
);

export default Popup;
