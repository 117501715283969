import React from 'react';
import GatsbyImage from 'common/GatsbyImage';
import './Intro.scss';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

interface IntroProps {
  handleStartButtonClick: () => void;
  introImage: string;
  introContent: string;
  images: any;
  buttonText: { [key: string]: string };
}

const Intro: React.FC<IntroProps> = ({
  handleStartButtonClick,
  introImage,
  introContent,
  images,
  buttonText,
}) => (
  <div className="intro">
    <GatsbyImage
      className="intro__bg"
      image={images.find((p) => p.properties.id === introImage)?.properties.image}
      alt={introImage}
    />
    <div className="intro__content-wrapper">
      <div className="intro__content">
        <DangerouslySetInnerHtml html={introContent} />
      </div>
      <div className="nav">
        <div
          onClick={handleStartButtonClick}
          className="nav__link-container"
          role="button"
          tabIndex={0}
        >
          <div className="nav__button">
            <span className="icon-arrow icon-arrow--reverse" />
          </div>
          <div className="nav__link-label">{buttonText.start}</div>
        </div>
      </div>
    </div>
  </div>
);

export default Intro;
