import React from 'react';
import classNames from 'classnames';
import GatsbyImage from 'common/GatsbyImage';
import './QuestionPage.scss';

interface IPropsQuestionPage {
  userAnswers: any;
  images: any;
  page: any;
  handleAnswer: (questionId: string, answer: string) => void;
  questions: any;
}

const QuestionPage: React.FC<IPropsQuestionPage> = ({
  userAnswers,
  images,
  handleAnswer,
  page,
  questions,
}) => {
  const questionById = (id) => questions.find((q) => q.id === id);

  return (
    <div className="question-page">
      {page.questions.map((questionId) => {
        const question = questionById(questionId);

        return (
          <div className="question">
            {question.text}
            <div
              className={`answers ${
                question.answerLayout === 'row' ? 'row-layout' : 'column-layout'
              }`}
            >
              {question.answerOptions.map((option) => (
                <div
                  role="button"
                  tabIndex={0}
                  className="answer-option__container"
                  onClick={() => handleAnswer(question.id, option.text)}
                >
                  {option.imageId && (
                    <GatsbyImage
                      className="answer-option__image"
                      image={
                        images.find((p) => p.properties.id === option.imageId).properties.image
                      }
                    />
                  )}
                  <div
                    key={option.text}
                    className={classNames('answer-option', {
                      selected: userAnswers[question.id] === option.text,
                    })}
                  >
                    {option.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionPage;
