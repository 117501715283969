import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link, graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';

import Results from 'components/HelpMeChoose/Results';
import Intro from 'components/HelpMeChoose/Intro';
import QuestionPage from 'components/HelpMeChoose/QuestionPage';
import ProgressIndicator from 'components/HelpMeChoose/ProgressIndicator';
import Navigation from 'components/HelpMeChoose/Navigation';
import Seo from 'common/Seo';
import { IPropsHelpMeChoosePage } from './models';
import 'styles/main.scss';
import 'layout/Layout/Layout.scss';
import './HelpMeChoosePage.scss';
import 'swiper/swiper-bundle.css';

const HelpMeChoosePage: FC<IPropsHelpMeChoosePage> = ({
  data: {
    helpMeChoose: {
      quiz,
      images,
      defaultCompositions: {
        brandSettings: { brandLogoText, brandLogoTextMedia },
        brandSettings,
        siteSettings,
      },
      seo,
    },
  },
}) => {
  const [currentPage, setCurrentPage] = useState('1');
  const [userAnswers, setUserAnswers] = useState({});
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [result, setResult] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isIntro, setIsIntro] = useState(true);
  const [pageHistory, setPageHistory] = useState(['1']);

  const quizData = JSON.parse(quiz);
  const { buttonText, questions, pages, results, intro } = quizData;

  const pageById = useMemo(() => (id) => pages.find((p) => p.id === id), [pages]);

  useEffect(() => {
    if (currentPage) {
      const allQuestionsAnswered = pageById(currentPage).questions.every((questionId) =>
        Object.prototype.hasOwnProperty.call(userAnswers, questionId)
      );
      setIsNextButtonEnabled(allQuestionsAnswered);
    }
  }, [currentPage, userAnswers]);

  const handleAnswer = (questionId, answer) => {
    setUserAnswers((prevAnswers) => ({ ...prevAnswers, [questionId]: answer }));
  };

  const handleNextButtonClick = () => {
    if (!isNextButtonEnabled) {
      return;
    }
    const nextPage = pages.find((page, index) => {
      if (index <= pages.findIndex((p) => p.id === currentPage)) {
        return false;
      }

      const conditions = page.conditions || [];

      return conditions.every((condition) => {
        if (condition.answer) {
          return userAnswers[condition.questionId] === condition.answer;
        }
        if (condition.answerNot) {
          return userAnswers[condition.questionId] !== condition.answerNot;
        }

        return false;
      });
    });

    if (nextPage) {
      setCurrentPage(nextPage.id);
      setPageHistory((prevHistory) => [...prevHistory, nextPage.id]);
      setIsCompleted(false);
    } else {
      const resultPage = results.find((page) => {
        const conditions = page.conditions || [];

        return conditions.every((condition) => {
          if (condition.answer) {
            return userAnswers[condition.questionId] === condition.answer;
          }
          if (condition.answerNot) {
            return userAnswers[condition.questionId] !== condition.answerNot;
          }

          return false;
        });
      });

      setResult(resultPage);
      setIsCompleted(true);
    }
  };

  const handlePreviousButtonClick = () => {
    if (isCompleted) {
      const lastPageId = pageHistory[pageHistory.length - 1];
      setCurrentPage(lastPageId);
    } else {
      const previousPageId = pageHistory[pageHistory.length - 2];
      setCurrentPage(previousPageId);
      setPageHistory((prevHistory) => prevHistory.slice(0, -1));
    }
    setIsCompleted(false);
  };

  const handleRestartButtonClick = () => {
    setUserAnswers({});
    setCurrentPage('1');
    setResult(null);
    setPageHistory(['1']);
    setIsCompleted(false);
  };

  const handleStartButtonClick = () => {
    setIsIntro(false);
  };

  const page = pageById(currentPage);

  return (
    <div className="help-me-choose">
      <Seo siteSettings={siteSettings} brandSettings={brandSettings} seo={seo} />
      <div className="help-me-choose__header">
        <Link to="/">
          <GatsbyImage className="brand-logo__text" image={brandLogoText} />
          <GatsbyImage className="brand-logo__text" image={brandLogoTextMedia} />
        </Link>
      </div>
      {isIntro ? (
        <Intro
          handleStartButtonClick={handleStartButtonClick}
          images={images}
          introContent={intro.content}
          introImage={intro.imageId}
          buttonText={buttonText}
        />
      ) : (
        <div className="help-me-choose__content">
          <ProgressIndicator pages={pages} currentPage={currentPage} isCompleted={isCompleted} />

          {isCompleted ? (
            <Results
              result={result}
              handlePreviousButtonClick={handlePreviousButtonClick}
              handleRestartButtonClick={handleRestartButtonClick}
              images={images}
              buttonText={buttonText}
            />
          ) : (
            <>
              <QuestionPage
                page={page}
                questions={questions}
                userAnswers={userAnswers}
                images={images}
                handleAnswer={handleAnswer}
              />

              <Navigation
                isCompleted={isCompleted}
                pageHistory={pageHistory}
                handlePreviousButtonClick={handlePreviousButtonClick}
                handleNextButtonClick={handleNextButtonClick}
                handleRestartButtonClick={handleRestartButtonClick}
                isNextButtonEnabled={isNextButtonEnabled}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const query = graphql`
  query HelpMeChoosePage($link: String = "") {
    helpMeChoose(link: { eq: $link }) {
      seo {
        ...FragmentSeo
      }
      quiz
      images {
        properties {
          id
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
    }
  }
`;

export default HelpMeChoosePage;
